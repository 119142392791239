<template>
  <v-app>
    <div>
      <!-- Filter -->
      <div class="row justify-content-between mt-n3">
        <div class="col-md-5 col-12">
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('days')"
            v-bind:class="{
              'btn-info': btnActive.days,
              'btn-outline-info': btnActive.days == false,
            }"
          >
            Hari Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('month')"
            v-bind:class="{
              'btn-info': btnActive.month,
              'btn-outline-info': btnActive.month == false,
            }"
          >
            Bulan Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('lastMonth')"
            v-bind:class="{
              'btn-info': btnActive.lastMonth,
              'btn-outline-info': btnActive.lastMonth == false,
            }"
          >
            Bulan Kemarin
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            @click="btnActiveOnClick('year')"
            v-bind:class="{
              'btn-info': btnActive.year,
              'btn-outline-info': btnActive.year == false,
            }"
          >
            Tahun Ini
          </button>
          <button
            class="btn mx-1 btn-timeseries"
            style="
              padding-left: 4px;
              padding-right: 4px;
              padding-top: 5px;
              padding-bottom: 5px;
            "
            v-bind:class="{
              'btn-info': btnActive.period,
              'btn-outline-info': btnActive.period == false,
            }"
            @click="btnPeriodeOnClick"
          >
            Periode
          </button>
        </div>
        <div class="col-md-3 col-12 mt-md-0 mt-n4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text squared>
                <b-icon-search></b-icon-search>
              </b-input-group-text>
            </template>
            <b-form-input
              type="text"
              v-model="filter.name"
              placeholder="Cari..."
              @keyup="filterByName"
            ></b-form-input>
          </b-input-group>
          <small class="text-muted">*Cari Nama, No atau Orang Tua Pasien</small>
        </div>
        <div class="col-md-4 col-12">
          <b-input-group>
            <v-dialog
              ref="dialog"
              v-model="datePickerPopUp"
              :return-value.sync="filter.birt_date"
              persistent
              width="350px"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  placeholder="Tanggal Lahir"
                  id="input-trigger-datepicker"
                  v-model="filter.birt_date"
                >
                </b-form-input>
              </template>
              <v-date-picker
                locale="id"
                v-if="datePickerPopUp"
                v-model="filter.birt_date"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="datePickerPopUp = false"
                  >Cancel</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(filter.birt_date)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
            <template #append>
              <b-button squared @click="filterByBirtDate" variant="success"
                >Cari</b-button
              >
              <b-button squared @click="resetFilter" variant="danger"
                >Reset</b-button
              >
            </template>
          </b-input-group>
        </div>
      </div>

      <!-- timeseries -->
      <div class="row justify-content-start align-items-center mt-3">
        <div class="col-md-2">
          <span
            v-if="recordData != null"
            style="width: 150px; color: #a12260 !important"
            class="font-weight-bold"
            >{{ recordData }} Baris Data</span
          >
        </div>
        <div class="col-md-8" v-if="onShowPeriod">
          <span class="d-none d-sm-block">
            <b-input-group>
              <b-form-input
                v-model="filter.start_date"
                type="text"
                placeholder="Awal"
                autocomplete="off"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="filter.start_date"
                  button-only
                  right
                  reset-button
                  locale="id"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-append>
              <b-button squared variant="light">s/d</b-button>
              <b-form-input
                v-model="filter.end_date"
                type="text"
                placeholder="Akhir"
                autocomplete="off"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="filter.end_date"
                  button-only
                  right
                  reset-button
                  locale="id"
                  aria-controls="example-input"
                ></b-form-datepicker>
              </b-input-group-append>
              <template #append>
                <b-button squared @click="pagination" variant="success"
                  >Cari</b-button
                >
                <b-button squared @click="resetFilterPeriod" variant="danger"
                  >Reset</b-button
                >
              </template>
            </b-input-group>
          </span>
          <span class="d-block d-sm-none">
            <div class="row">
              <div class="col-12 pb-1">
                <b-input-group>
                  <b-form-input
                    v-model="filter.start_date"
                    type="text"
                    placeholder="Awal"
                    autocomplete="off"
                    readonly
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filter.start_date"
                      button-only
                      right
                      reset-button
                      locale="id"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-12 pt-0">
                <b-input-group>
                  <b-button squared variant="light">s/d</b-button>
                  <b-form-input
                    v-model="filter.end_date"
                    type="text"
                    placeholder="Akhir"
                    autocomplete="off"
                    readonly
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filter.end_date"
                      button-only
                      right
                      reset-button
                      locale="id"
                      aria-controls="example-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <template #append>
                    <b-button squared @click="pagination" variant="success"
                      >Cari Periode</b-button
                    >
                    <b-button
                      squared
                      @click="resetFilterPeriod"
                      variant="danger"
                      >Reset Periode</b-button
                    >
                  </template>
                </b-input-group>
              </div>
            </div>
          </span>
        </div>
      </div>

      <!-- Table -->
      <b-table
        striped
        hover
        responsive="sm"
        class="mt-3"
        :items="items"
        :fields="fields"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{
              width:
                field.key === 'actions'
                  ? purpose == 'master'
                    ? '17%'
                    : ''
                  : '',
              width: field.key === 'responsible_doctor' ? '10%' : '',
            }"
          />
        </template>

        <template #cell(patient_name)="data">
          <span class="d-block">{{ data.item.patient_name }}</span>
          <span class="d-block">{{ "(" + data.item.patient_id + ")" }}</span>
        </template>

        <template #cell(birt_date)="data">
          <ul :style="'list-style-type: none;'">
            <li>
              {{ data.item.display_birt_date }}
            </li>
            <li>
              {{ data.item.parent_name }}
            </li>
          </ul>
        </template>

        <!-- <template #cell(doctor_in_charge)="data">
          <div
            v-for="(doctorName, index) in data.item.doctor_in_charge_name"
            :key="index"
          >
            <li>
              {{ doctorName }}
            </li>
          </div>
        </template> -->

        <template #cell(doctor)="data">
          <span class="font-weight-bolder">Dokter Yang Menangani :</span>

          <div
            v-for="(doctorName, index) in data.item.doctor_in_charge_name"
            :key="index"
          >
            <li>
              {{ doctorName }}
            </li>
          </div>

          <span class="font-weight-bolder">DPJP :</span>

          <div
            v-for="(doctorName, index) in data.item.responsible_doctor_name"
            :key="index"
          >
            <li>
              {{ doctorName }}
            </li>
          </div>
        </template>

        <!-- <template #cell(responsible_doctor)="data">
          
          <div
            v-for="(doctorName, index) in data.item.responsible_doctor_name"
            :key="index"
          >
            <li>
              {{ doctorName }}
            </li>
          </div>
        </template> -->

        <template #cell(photo)="data">
          <div
            class="d-flex justify-content-center align-items-center image-container"
          >
            <div style="height: 50px; width: 50px; position: relative">
              <img class="image" :src="data.item.photo" />
            </div>
          </div>
        </template>

        <template #cell(actions)="data">
          <template v-if="purpose == 'master'">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-12">
                <div class="d-flex justify-content-center">
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({ path: '/patient/detail/' + data.item.id })
                    "
                    ><i class="fas fa-eye px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Ubah"
                    v-if="manipulateBtn == true"
                    @click="
                      $router.push({ path: '/patient/edit/' + data.item.id })
                    "
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Riwayat"
                    v-if="historyBtn == true"
                    @click="
                      $router.push({ path: '/patient/history/' + data.item.id })
                    "
                    ><i class="fas fa-list-alt px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Pembayaran"
                    v-if="paymentBtn == true"
                    @click="
                      $router.push({ path: '/patient/payment/' + data.item.id })
                    "
                    ><i class="fas fa-money-bill-wave px-0"></i
                  ></b-button>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-12 mt-1">
                <div class="d-flex justify-content-center">
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Tambah Rekam Medis"
                    v-if="addMedicalRecordBtn == true"
                    @click="
                      $router.push(
                        `/patient/cases?patient_id=${data.item.id}&patient_name=${data.item.name} (${data.item.patient_id})&allergy=${data.item.allergy}&notes=${data.item.notes}`
                      )
                    "
                    ><i class="fas fa-user-md px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Tambah Resep"
                    @click="
                      $router.push(
                        `/prescriptions/add?patient_id=${data.item.id}&patient_name=${data.item.name} (${data.item.patient_id})`
                      )
                    "
                    ><i class="fas fa-receipt px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    v-if="manipulateBtn == true"
                    @click="btnDeleteOnClick(data.item.id)"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="btn-warning"
                    v-b-tooltip.hover
                    title="Cetak Blanko"
                    v-if="medicalRecordBtn == true"
                    @click="
                      $router.push({
                        path:
                          '/patient/themplate-print-patient/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-print px-0"></i
                  ></b-button>
                </div>
              </div>
            </div>
          </template>
          <template v-if="purpose == 'modal'">
            <b-button
              size="sm"
              class="mr-1 btn-info"
              @click="
                choosePatient({
                  id: data.item.id,
                  name: data.item.name + ` (${data.item.patient_id})`,
                  allergic: data.item.allergy,
                  notes: data.item.notes,
                  doctor_in_charge: data.item.doctor_in_charge,
                })
              "
              >Pilih</b-button
            >
            <b-button
              size="sm"
              class="mr-1 btn"
              variant="primary"
              @click="
                setPatienForAddNewDoctor(
                  data.item.id,
                  data.item.name + ` (${data.item.patient_id})`
                )
              "
              ><i class="fas fa-plus"></i> Dokter</b-button
            >
          </template>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>

      <!-- Modal Add Doctor -->
      <b-modal
        id="modal-add-doctor"
        size="xl"
        :title="'Tambah Dokter - ' + patient_dis.name"
        hide-footer
      >
        <!-- form -->
        <b-form @submit.stop.prevent="addDoctorOnSubmit">
          <!-- Input Doctor -->
          <b-form-group
            id="input-group-doctor"
            label="Dokter Yang Menangani:"
            label-for="input-doctor"
          >
            <treeselect
              v-model="form.doctor_in_charge"
              :multiple="true"
              :options="doctors"
              required
              placeholder="Pilih Dokter"
            />
            <small class="text-danger">{{ error.doctor_in_charge }}</small>
          </b-form-group>

          <!-- Input Responsible Doctor -->
          <b-form-group
            id="input-group-doctor"
            label="Dokter Penanggung Jawab (DPJP):"
            label-for="input-doctor"
          >
            <treeselect
              v-model="form.responsible_doctor"
              :multiple="true"
              :options="doctors"
              required
              placeholder="Pilih Dokter"
            />
          </b-form-group>

          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            class="ml-2"
            variant="default"
            @click="$router.push('/patient/list')"
          >
            Cancel
          </b-button>
        </b-form>
      </b-modal>
    </div>
  </v-app>
</template>

<script>
import { debounce } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import moment from "moment";

export default {
  props: {
    purpose: String,
    selectedDoctor: String,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        birt_date: "",
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      recordData: 0,

      // data doctors
      doctors: {},
      // form add doctor
      form: {
        doctor_in_charge: [],
        responsible_doctor: [],
        _method: "PUT",
      },
      // error form doctor
      error: {},
      // modal display data
      patient_dis: {
        id: "",
        name: "",
      },

      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        // {
        //   key: "patient_id",
        //   label: "Nomor",
        //   sortable: true,
        // },
        {
          key: "patient_name",
          label: "Nama (Nomor)",
          sortable: true,
        },
        {
          key: "birt_date",
          label: "Tgl. Lahir & Orang Tua",
          sortable: true,
        },
        // {
        //   key: "gender",
        //   label: "Jenis Kelamin",
        //   sortable: true,
        // },
        // {
        //   key: "parent_name",
        //   label: "Orang Tua",
        //   sortable: true,
        // },
        // {
        //   key: "doctor_in_charge",
        //   label: "Dokter Yang Menangani",
        //   sortable: true,
        // },
        // {
        //   key: "responsible_doctor",
        //   label: "DPJB",
        //   sortable: true,
        // },
        {
          key: "doctor",
          label: "Dokter",
          sortable: true,
          thClass: "th-doctor",
        },

        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Other
      datePickerPopUp: false,
      // access Data
      manipulateBtn: false,
      paymentBtn: false,
      historyBtn: false,
      medicalRecordBtn: false,
      addMedicalRecordBtn: false,
      // current user
      currentUser: getUser(),
    };
  },

  methods: {
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.pagination();
    },

    async pagination() {
      let route;
      if (this.currentUser.user_type_id == 1) {
        route = `patients/get-by-doctor/${this.currentUser.id}`;
      } else {
        route = "patients";
      }
      if (
        this.selectedDoctor != "" &&
        typeof this.selectedDoctor !== "undefined"
      ) {
        route = `patients/get-by-doctor/${this.selectedDoctor}`;
      }
      let filterParams = `&name=${this.filter.name}&birt_date=${this.filter.birt_date}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    resetFilter() {
      this.btnActiveOnClick(this.lastPeriodType);
      this.pagination();
    },

    resetFilterPeriod() {
      this.btnActiveOnClick(this.lastPeriodType);
      this.pagination();
    },

    filterByName() {
      debounce(() => {
        this.filter.start_date = "";
        this.filter.end_date = "";
        if (this.filter.name == "") {
          this.btnActiveOnClick(this.lastPeriodType);
        }
        this.pagination();
      }, 500);
    },

    filterByBirtDate() {
      this.pagination();
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("patients/" + id);
      // If Deleted
      if (result) {
        this.$root.$emit("UpdateNewPatient");
        this.pagination();
      }
    },

    choosePatient(value) {
      this.$emit("choosePatient", value);
    },

    async setPatienForAddNewDoctor(id, name) {
      await this.getDoctorOptions();
      this.patient_dis.id = id;
      this.patient_dis.name = name;

      // get data doctor patient by id
      let data = await module.get("patients/" + this.patient_dis.id);

      this.form = data;

      // set data doctor
      this.form.doctor_in_charge = data.doctor_in_charge;
      this.form.responsible_doctor = data.responsible_doctor;

      this.$bvModal.show("modal-add-doctor");
    },

    // submit add new doctors
    async addDoctorOnSubmit() {
      // console.log(this.form,"FORM");
      this.form._method = "PUT";
      // delete element photo
      delete this.form.photo;

      // stringify doctor
      // this.form.doctor_in_charge = JSON.stringify(this.form.doctor_in_charge);
      // this.form.responsible_doctor = JSON.stringify(
      //   this.form.responsible_doctor
      // );

      let data = {
        id: this.form.id,
        doctor_in_charge: JSON.stringify(this.form.doctor_in_charge),
        responsible_doctor: JSON.stringify(this.form.responsible_doctor),
        _method: this.form._method,
      };

      let response = await module.submit(
        data,
        "patients/" + this.patient_dis.id + "/mini-update"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );

        // this.form.doctor_in_charge = JSON.parse(this.form.doctor_in_charge);
        // this.form.responsible_doctor = JSON.parse(this.form.responsible_doctor);
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$bvModal.hide("modal-add-doctor");
        this.pagination();
      }
    },

    async getDoctorOptions() {
      let response = await module.setTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "3002") {
          this.manipulateBtn = true;
        }
        if (access_right[a] == "3003") {
          this.historyBtn = true;
        }
        if (access_right[a] == "3004") {
          this.paymentBtn = true;
        }
        if (access_right[a] == "3005") {
          this.medicalRecordBtn = true;
        }
        if (access_right[a] == "3006") {
          this.addMedicalRecordBtn = true;
        }
      }
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.pagination();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }
    },
  },

  mounted() {
    // Get Data
    this.pagination();
    this.setActiveMenu();
  },
};
</script>
<style>
.th-doctor {
  max-width: 20%;
}
</style>